import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const backendUrl = window.REACT_APP_BACKEND_URL || 'http://localhost:3000'; // Fallback, falls die Umgebungsvariable nicht gesetzt ist

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        question: ''
    });

    const [alertData, setAlertData] = useState({
        visible: false,
        message: '',
        type: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${backendUrl}/questions`, formData);
            setAlertData({
                visible: true,
                message: 'Frage erfolgreich gesendet!',
                type: 'success'
            });
            setFormData({ name: '', email: '', question: '' });
        } catch (error) {
            setAlertData({
                visible: true,
                message: 'Es gab einen Fehler beim Senden Ihrer Frage.',
                type: 'danger'
            });
        }
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6">
                    {alertData.visible && (
                      <div className={`alert alert-${alertData.type}`} role="alert">
                          {alertData.message}
                      </div>
                    )}
                    <h2>Stellen Sie eine Frage:</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">E-Mail (optional)</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Frage</label>
                            <textarea
                                className="form-control"
                                name="question"
                                value={formData.question}
                                onChange={handleInputChange}
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Frage senden
                        </button>
                    </form>
                </div>
                <div className="col-md-6">
                    <h2>Livestream:</h2>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            className="embed-responsive-item"
                            src="https://vimeo.com/event/3687952/embed/759dfb8ac7"
                            title="Webinar Livestream"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
